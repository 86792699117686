::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
}
::-moz-placeholder { /* Firefox 19+ */
  color: white;
}
:-ms-input-placeholder { /* IE 10+ */
  color: white;
}
:-moz-placeholder { /* Firefox 18- */
  color: white;
}

.dropdown_control{
  background-color: transparent !important;
  color: blue
}
.dropdown_placeholder{
   background: transparent;
   color: rgb(125, 218, 255);
}

.mainPageView {
  display: flex;
  flex-direction: column;
  background: rgb(58, 58, 60);
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/images/login_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:100%;
  height: 100%;
}
.mainView {
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #003459 0%, #104469 35%, #092f6a 100%);
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  min-height:100%;
  height: 100%
}
.menu{
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #00b5d3 0%, #007ea7 35%, #092f6a 100%);
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
}
.menu_row{
  display:flex;
  flex-direction:row;
  width:200px;
  margin-top:5px;
  align-items: center;
  justify-content: flex-start;
}
.menu_icon{
  display: flex;
  background-color:#004271;
  border-radius:5px;
  min-width:35px;
  height:35px;
  justify-content:center;
  align-items:center;
}
.mousehover{
  opacity: 1;
}
.mousehover:hover{
  opacity: .5;
  cursor: pointer;
}
.center_row{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-start;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 25% auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #0dc5c1;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #0dc5c1;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

